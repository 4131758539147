import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import strftime from 'strftime'
import classnames from 'classnames'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/blog_post.scss'


class BlogPost extends React.Component {

  render() {
    const post = _.get(this.props, 'data.markdownRemark')
    return (
      <Layout>
        <SEO title={_.get(post, 'fields.title', "BLOG")}/>
        <div
          className={classnames('blog-post-meta')}>
          <span>{strftime('%Y%m%d%H%M', new Date(_.get(post, 'fields.createdAt')))}</span>
        </div>
        <div
          className={classnames('blog-post')}
          dangerouslySetInnerHTML={{__html: _.get(post, 'html')}} />
      </Layout>
    );
  }
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        title
        createdAt
      }
    }
  }
`
